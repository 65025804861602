@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.cdnfonts.com/css/euclid-circular-a");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.new-banner-container{
  background-color: #F1FAFF;
}
.new-banner img{
  width: 100%;
}
.new-banner-row{
  align-items: center;
  justify-content: center;
}
.new-banner-content h3{
  color: #252B42;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 60px;
}
.new-banner-button button{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  background-color: #0397DD;
  border: none;
  border-radius: 3.5px;
  width: 160px;
  padding: 5px 0;
}
.new-nav-row{
  align-items: center;
  justify-content: space-between;
}
.new-footer{
  background: #2c3333;
}
.policy-info{
  margin-left: -10px;
}
.foot-info{
 margin: 10px 10px;
 color: #fff;
 font-family: "Inter", sans-serif;
}

.cont-number1 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.cont-mail1 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.cont-number-head1 {
  border-right: 1px solid #fff;
  padding: 0 10px;
}
.cont-number-head1 i{
  color: #fff;
}
.c-head i{
  color: #fff;
}
.footer-new-row{
  justify-content: space-between;
  padding: 20px 0;
  padding-top: 35px;
}



.navbar-nav li {
  margin: 0 20px;
  color: #737373;
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.948px; /* 121.055% */
  letter-spacing: 0.141px;
}
.navbar {
  padding: 10px 80px;
  width: 100%;
  position: absolute;
  z-index: 9;
  border-bottom: 1px solid #ebebeb;
  background: #fff;
}
.header-contact {
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  padding: 5px 80px;
  padding-top: 15px;
}
.cont-number {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.cont-mail {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.cont-number-head {
  border-right: 1px solid #000;
  padding: 0 10px;
}
.header-contact i {
  font-size: 13px;
}
.login-button {
  color: #0397dd;
  text-align: right;
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15.535px; /* 129.462% */
  letter-spacing: 0.141px;
  text-decoration: none;
}
.register-button {
  border-radius: 3.5px;
  background: #0397dd;
  padding: 10px 15px;
  color: var(--light-text-color, #fff);
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.535px; /* 129.462% */
  letter-spacing: 0.141px;
  text-decoration: none;
  text-decoration: none;
}
.register-button:hover {
  background-color: #fff;
  border: 1px solid #0397dd;
  color: #0397dd;
  transition: all ease-out 0.5s;
}
.navbar-toggler-icon {
  background-image: none !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.dropdown-menu li {
  text-align: start !important;
  font-family: "Euclid Circular A";
  font-size: 13px;
  font-size: 500;
  margin: 5px 0;
}
.dropdown-menu {
  width: 250px !important;
  position: absolute !important;
}
.dropdown-item {
  padding: 10px 20px;
  color: #666666;
}

.banner-side img {
  width: 100%;
  height: auto;
}
.banner-content h4 {
  color: var(--text-color, #252b42);
  font-family: "Montserrat";
  font-size: 40.957px;
  font-style: normal;
  font-weight: 800;
  line-height: 56.492px; /* 137.931% */
  letter-spacing: 2.2px;
}
.banner-content p {
  color: #737373;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 200% */
  letter-spacing: 0.141px;
}
.banner-container {
  background: #fafafa;
  padding: 10px 80px;
}
.quote-now {
  border-radius: 26.128px;
  background: #0397dd;
  padding: 8px 25px;
  border: none;
  color: var(--light-text-color, #fff);
  text-align: center;
  font-family: "Montserrat";
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.535px; /* 141.231% */
  letter-spacing: 0.141px;
  cursor: pointer;
}
.learn-btn {
  border-radius: 26.128px;
  background: #fff;
  padding: 8px 25px;
  border: 1px solid #0397dd;
  color: var(--light-text-color, #0397dd);
  text-align: center;
  font-family: "Montserrat";
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.535px; /* 141.231% */
  letter-spacing: 0.141px;
}
.why-us-container {
  padding: 10px 80px;
}
.why-small-box {
  background: #fff;
  box-shadow: 0px 9.18px 13.42px 0px rgba(0, 0, 0, 0.07);
  padding: 20px;
  padding-top: 30px;
  margin: 10px 0;
}
.why-small-box h6 {
  color: var(--text-color, #252b42);
  font-family: "Montserrat";
  font-size: 11.298px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.948px; /* 150% */
  letter-spacing: 0.071px;
}
.why-small-box p {
  color: #737373;
  font-family: "Montserrat";
  font-size: 9.886px;
  font-style: normal;
  font-weight: 500;
  line-height: 14.123px; /* 142.857% */
  letter-spacing: 0.141px;
}
/*** 
.why-us-container{
  position: absolute;
  margin-top: -40px;  
}  
***/
.why-small-box i {
  color: #0397dd;
  font-size: 14px;
}
.why-small-box:hover {
  background-color: #0397dd;
  color: #fff;
  transition: all ease-out 0.5s;
}
.why-small-box:hover h6 {
  color: #fff;
  transition: all ease-out 0.5s;
}
.why-small-box:hover p {
  color: #fff;
  transition: all ease-out 0.5s;
}
.why-small-box:hover i {
  color: #fff;
  transition: all ease-out 0.5s;
}
.consult-box {
  border-radius: 7px;
  border: 1px solid #ccc;
  background: #fff;
}
.consult-box h5 {
  color: #000;
  font-family: "Euclid Circular A";
  font-size: 18.842px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.378px; /* 140% */
  letter-spacing: -0.377px;
}
.consult-box h6 {
  color: #000;
  font-family: "Euclid Circular A";
  font-size: 14.131px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.61px */
  letter-spacing: -0.283px;
}
.consult-container {
  padding: 10px 80px;
}
.consult-box {
  padding: 20px;
}
.btn {
  width: 170px;
  height: 36px;
  background-color: #0397dd;
  color: #fff;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.btn span {
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s;
}

.btn-text-one {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-family: "Euclid Circular A";
  font-size: 10px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}

.btn-text-two {
  position: absolute;
  width: 100%;
  top: 150%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-family: "Euclid Circular A";
  font-size: 10px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}

.btn:hover .btn-text-one {
  top: -100%;
}

.btn:hover .btn-text-two {
  top: 50%;
}
.consult-heading h4 {
  color: #000;
  font-family: "Euclid Circular A";
  font-size: 42.394px;
  font-style: normal;
  font-weight: 700;
  line-height: 54.641px; /* 128.889% */
  letter-spacing: -0.848px;
}
.consult-heading h6 {
  color: #737373;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 200% */
  letter-spacing: 0.141px;
}
.offer-img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background: rgba(3, 151, 221, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.offering-box {
  display: flex;
  justify-content: space-evenly;
}
.offer-content h6 {
  color: #000;
  font-family: "Euclid Circular A";
  font-size: 18.842px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.378px; /* 140% */
  letter-spacing: -0.377px;
}
.offer-content li {
  list-style: none;
}

.sub-link {
  color: #4e4e4e;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.494px; /* 174.959% */
  letter-spacing: -0.28px;
  text-decoration: none;
}
.view-btn {
  display: inline-block;
  padding: 5px 25px;
  font-size: 14px;
  border-radius: 20px;
  font-weight: 500;
  color: #0397dd;
  border: 1px solid #0397dd;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
  font-family: inherit;
}

.view-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #53b8e7;
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: -1;
}

.view-btn:hover::before {
  transform: translateX(0);
}
.client-heading h5 {
  color: #303030;
  font-family: "Euclid Circular A";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.626px; /* 110.359% */
  letter-spacing: 1.75px;
}
.client-heading p {
  color: #303030;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 200% */
  letter-spacing: 0.8px;
}
.client-row {
  justify-content: space-between;
}
.client-row img {
  width: 100%;
  height: auto;
}
.business-container {
  background: rgba(3, 151, 221, 0.04);
}
.auto-pilot-box {
  border-radius: 5px;
  border: 1px solid #ebebeb;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 20px 5px;
  margin: 10px 0;
}
.business-container h5 {
  color: var(--neutral-d-grey, #4d4d4d);
  text-align: center;
  font-family: "Euclid Circular A";
  font-size: 25.059px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.627px; /* 122.222% */
}
.auto-pilot-box h5 {
  color: var(--neutral-d-grey, #4d4d4d);
  text-align: center;
  font-family: "Euclid Circular A";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.059px; /* 128.571% */
}
.auto-pilot-box p {
  color: var(--neutral-grey, #717171);
  text-align: center;
  font-family: "Euclid Circular A";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 192.308% */
  margin-bottom: 0;
}
.footer-container {
  padding: 10px 80px;
  background: #2c3333;
}
.foot-button {
  all: unset;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 20px;
  border: #fff solid 0.15em;
  border-radius: 0.25em;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  overflow: hidden;
  transition: border 300ms, color 300ms;
  user-select: none;
}

.foot-button p {
  z-index: 1;
  font-family: "Euclid Circular A";
  margin-bottom: 0;
}

.foot-button:hover {
  color: #212121;
}

.foot-button:active {
  border-color: teal;
}

.foot-button::after,
.foot-button::before {
  content: "";
  position: absolute;
  width: 9em;
  aspect-ratio: 1;
  background: #fff;
  border-radius: 50%;
  transition: transform 500ms, background 300ms;
}

.foot-button::before {
  left: 0;
  transform: translateX(-8em);
}

.foot-button::after {
  right: 0;
  transform: translateX(8em);
}

.foot-button:hover:before {
  transform: translateX(-1em);
}

.foot-button:hover:after {
  transform: translateX(1em);
}

.foot-button:active:before,
.foot-button:active:after {
  background: teal;
}
.footer-box h4 {
  color: #fff;
  font-family: "Euclid Circular A";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.footer-box h5 {
  color: #fff;
  font-family: "Euclid Circular A";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer-box h6 {
  color: #fff;
  font-family: "Euclid Circular A";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.footer-box1 h5 {
  color: #fff;
  font-family: "Euclid Circular A";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.footer-box1 li {
  list-style: none;
  color: #fff;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 20px 0;
}
.footer-box1 ul {
  padding-left: 0;
}
.footer-row {
  justify-content: space-between;
}

@media (max-width: 600px) {
  .navbar {
    padding: 10px 0;
  }
  .header-contact {
    padding: 10px 20px;
  }
  .contact-info {
    display: none !important;
  }
  .banner-container {
    background: #fafafa;
    padding: 10px;
  }
  .why-us-container {
    padding: 10px;
  }
  .consult-container {
    padding: 10px;
  }
  .footer-container {
    padding: 10px;
  }
}
@media (max-width: 991px) {
  .navbar-nav {
    background-color: #000;
    padding: 15px 0;
  }
  .nav-link {
    color: #fff !important;
    margin: 15px 0;
  }
}

/*** service details ***/
.nav-pills button {
  text-align: left;
  color: #3c3c3c;
  font-family: "Euclid Circular A";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.75px;
  margin: 2px 0;
  padding: 5px 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: #0397dd;
  font-family: "Euclid Circular A";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
}
.service-detail-row h6 {
  color: #000;
  font-family: "Euclid Circular A";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.1px;
}
.detail-line {
  margin-top: -1px;
}
.vertical-tabs h4 {
  color: #000;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
}
.vertical-tabs p {
  color: #5b5b5b;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px; /* 233.333% */
  letter-spacing: 1.5px;
}
@media (max-width: 991px) {
  .nav-pills .nav-link {
    color: #3c3c3c !important;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #0397dd !important;
  }
}
.left-service h5 {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 218.75% */
  letter-spacing: 1.6px;
  padding-top: 2px;
}
.left-service span {
  color: #3c3c3c;
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  letter-spacing: 1.2px;
}
.price-button {
  padding: 8px 20px;
  font-size: 13px;
  font-weight: 500;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  z-index: 1;
}

.price-button::after,
.price-button::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.price-button::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #0397dd;
  border-radius: 50px;
}

.price-button::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  border-radius: 50px;
}

.price-button:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.price-button:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.price-button:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #232323;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.45px;
  border: none;
  border-bottom: 2.5px solid #0397dd;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
  border-bottom: 2.5px solid #0397dd;
}
.nav-tabs .nav-link {
  color: #373636;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.45px;
}
.nav-tabs button {
  margin: 0 20px;
  padding: 5px;
}
.nav-tabs {
  margin-left: -20px;
}
.tax-tab-content li {
  color: #696969;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 266.667% */
  letter-spacing: 0.75px;
  margin: 10px 0;
}
.video-heading h4 {
  text-align: center;
  color: #000;
  text-align: center;
  font-family: "Euclid Circular A";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.video-heading span {
  font-weight: 800;
}
.video-contentbox h5 {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
}
.video-viewbox iframe {
  width: 100%;
  height: 316px;
}
.video-box-row {
  align-items: center;
}
.faq-heading h5 {
  color: #000;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
}
.faq-box {
  margin: 10px 0;
}
.faq-box h4 {
  color: #3d3d3d;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.063px; /* 129.784% */
}
.faq-box p {
  color: #53686a;
  font-family: "Euclid Circular A";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 166.667% */
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .nav-tabs button {
    margin: 10px;
  }
  .nav-tabs .nav-link {
    color: #373636 !important;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    border: none;
    border-bottom: 2.5px solid #0397dd;
  }
  .right-service {
    margin-top: 20px;
  }
}

/*** register CSS ***/
.register-container {
  background: #b1d9db;
  height: 100vh;
}
.register-column {
  border-radius: 50px 0px 0px 50px;
  background: #fff;
  padding: 40px 0;
  height: 100vh;
}
.register-box-left img {
  width: 100%;
  height: auto;
}
.register-box-right {
  padding: 0 10px;
}
.register-box-right h4 {
  color: #000;
  font-family: "Euclid Circular A";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 136.5%; /* 49.14px */
  letter-spacing: 3.6px;
}
.register-box-right h6 {
  color: rgba(0, 0, 0, 0.85);
  font-family: "Euclid Circular A";
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.register-box-right span {
  color: #0397dd;
}
.register-form input:focus {
  border: none;
  box-shadow: none;
  background-color: #ecf9ff;
  border-radius: 4px;
}
.register-form input {
  border: none;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 0;
}
.register-form input::placeholder {
  color: #5c5c5c;
  font-family: "Euclid Circular A";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 136.5%; /* 25.935px */
  letter-spacing: 1.52px;
}
.register-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #0397dd;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border: none;
  width: 100%;
}

.register-btn:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: #1289c1;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
}

.register-btn:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.register-btn span {
  text-align: center;
  text-decoration: none;
  width: 100%;
  font-family: "Euclid Circular A";
  padding: 10px 20px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.7px;
  z-index: 20;
  transition: all 0.3s ease-in-out;
}

.register-btn:hover span {
  color: #fff;
  animation: scaleUp 0.3s ease-in-out;
}
.register-form small {
  color: #6b6a6a;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 136.5%; /* 27.3px */
  letter-spacing: 1.6px;
}
@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}
.main-register-row {
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .register-column {
    border-radius: 0;
  }
}
.field-icon {
  float: right;
  padding-right: 10px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
  opacity: 0.6;
  cursor: pointer;
}
.otp-inputbox {
  display: flex;
  gap: 20px;
}
.otp-inputbox input {
  border: 1px solid #d9d9d9;
  width: 40px;
  height: 40px;
  border-radius: 3px;
}
.otp-inputbox input::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #8c8c8c;
}
.card-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.payment-box {
  border-radius: 8px;
  background: #fff;
  box-shadow: 6px 4px 13px 0px rgba(0, 0, 0, 0.25);
}

.pay-icon {
  text-align: center;
}
.payment-form label {
  color: #888;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.new-field-icon {
  float: right;
  padding-right: 10px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  opacity: 0.6;
  cursor: pointer;
}
.payment-form input::placeholder {
  color: #525252;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.payment-form {
  padding: 20px 50px;
}
.payment-form input:focus {
  background-color: #ccedfd;
  box-shadow: none;
}

/*** dashboard css **/

/*** .home-container{
  padding-top: 10px !important;
}  ***/
.home-container {
  background: #f8fafb;
  min-height: 100vh;
  padding-top: 30px !important;
  z-index: 999;
}
header {
  background: transparent !important;
  height: 20px !important;
}
:root {
  --header-height: 3rem;
  --nav-width: 50px;

  --first-color: #f1f2f7;
  --first-color-alt: #f582a7;
  --white-color: #fff;
}

a {
  text-decoration: none;
}

main {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-size: 1rem;
  font-family: "Nunito Sans", sans-serif;
  transition: 0.5s;
  background: #f8fafb;
}
.fa-bars {
  color: #000;
}

.header {
  position: fixed;
  top: 88px;
  left: 0;
  height: var(--header-height);
  width: 11%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-color);
  padding: 0 1rem;
  transition: 0.5s;
}

.header-toggle {
  font-size: 1.25rem;
  cursor: pointer;
  color: var(--first-color);
}

.sidebar {
  position: fixed;
  top: 76px;
  left: -30%;
  height: 90vh;
  width: var(--nav-width);
  background-color: #fff;
  border: 1px solid #f1f2f7;
  padding-top: 1rem;
  transition: 0.5s;
}

.nav1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  height: 100%;
}

.nav-logo,
.nav-linksidebar {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav-logo {
  margin-bottom: 1rem;
}

.nav-logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
  cursor: pointer;
}

.nav-logo-name {
  color: #082431;
  font-weight: 500;
  font-size: 15px;
  font-family: "Poppins";
  letter-spacing: 1px;
}

.nav-linksidebar {
  position: relative;
  color: #273240;
  font-size: 11px;
  font-family: "Poppins";
  letter-spacing: 0.5px;
  transition: 0.5s;
  margin-bottom: 1.5rem;
}

.nav-linksidebar:hover {
  color: #5a6acf;
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

.active-bar {
  background: #f8f8f8;
}
.active-bar span {
  color: #0397dd;
  font-weight: 600;
}
.active-bar i {
  color: #0397dd !important;
}
.active-bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 38px;
  width: 3px;
  background-color: var(--white-color);
  transition: 0.5s;
}

.show1 {
  width: calc(var(--header-height) + 165px);
}

.space-toggle {
  padding-left: calc(var(--nav-width) + 1rem);
}

@media screen and (min-width: 300px) {
  main {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding: 0 1rem 0 calc(var(--nav-width) + 2rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding-left: calc(var(--nav-width) + 2rem);
  }

  .sidebar {
    left: 0;
    padding: 1rem 0 0 0;
  }

  .show {
    left: 0;
  }

  .space-toggle {
    padding-left: calc(var(--nav-width) + 168px);
  }
}

.inner-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
  padding: 8px 20px;
  z-index: 99;
  position: fixed;
}
.notify-navbox i {
  color: #898a8d;
  border-radius: 10px;
  background: rgba(235, 235, 235, 0.4);
  padding: 10px 10px;
}
.nav-imgbox img {
  width: 135px;
  height: 60px;
}
.nav-link-name {
  color: #0c0c0d;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.19px;
}
.nav-linksidebar i {
  font-size: 15px;
  color: #292d32;
}
.dash-head {
  display: flex;
  justify-content: space-between;
}
.dash-head h5 {
  color: #343434;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}
.dash-infobox {
  border-radius: 6px;
  border: 2px solid #eceef6;
  background: #fff;
  padding: 15px;
  margin: 10px 0;
}
.dash-infobox h4 {
  color: #232323;
  font-family: "Euclid Circular A";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}
.dash-link {
  color: #949494;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.table-column {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
}
#table_detail .hidden_row {
  display: none;
}
#table_detail i {
  cursor: pointer;
}
.bi-chevron-down {
  cursor: pointer;
}
#table_detail th {
  color: var(--grey-grey-50, #6b7280);
  font-family: "Euclid Circular A";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-top: 1px solid var(--grey-grey-20, #e5e7eb);
  border-bottom: 1px solid var(--grey-grey-20, #e5e7eb);
  padding: 20px;
  white-space: nowrap;
}
#table_detail td {
  color: #2f2f2f;
  font-family: "Euclid Circular A";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-top: 1px solid var(--grey-grey-20, #e5e7eb);
  padding: 20px;
  white-space: nowrap;
}
.table-column {
  overflow-x: scroll;
}
.hidden_row {
  border: none !important;
}
.hide-rowline {
  border-top: none !important;
}
.table-column h5 {
  color: var(--grey-grey-90, #111827);
  text-align: left;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.new-filter input::placeholder {
  padding-left: 20px;
  font-size: 14px;
}
.new-filter {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.new-filter button {
  border: none;
  padding: 5px 25px;
  border-radius: 8px;
  border: 1px solid var(--grey-grey-20, #e5e7eb);
  background: var(--grey-grey-0, #fff);
  border-radius: 3px;
  color: var(--grey-grey-90, #111827);
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.input-filterbox {
  display: flex;
}
.input-filterbox input::placeholder {
  color: #b1b1b1;
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 181.818% */
}
.input-filterbox input:focus {
  box-shadow: none;
}
.input-filterbox select {
  height: 33px;
  color: #b1b1b1;
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  width: 250px;
}
.new-filter input.search {
  width: 200px;
  border: 1px solid #dedede;
  display: block;
  padding: 5px 0;
  border-radius: 5px;
}
.new-filter input {
  height: 33px;
}
.new-filter h5 {
  padding-top: 4px;
}
@media (max-width: 1000px) {
  .new-filter {
    display: block;
  }
  .new-filter button {
    margin-top: 10px;
  }
}
@media (max-width: 530px) {
  .input-filterbox {
    display: block;
  }
  .input-filterbox input {
    margin: 10px 0;
  }
}
.input-filterbox input[type] {
  font-size: 14px;
}
.await .bi-dot {
  color: #f43f5e;
  font-size: 22px;
}
.paid .bi-dot {
  color: #10b981;
  font-size: 22px;
}
#table_detail button {
  border-radius: 4px;
  background: #0397dd;
  padding: 5px 15px;
  border: none;
  color: #fff;
}

/*** order details css ***/
.order-status {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 30px 1px rgba(224, 224, 224, 0.24);
  padding: 20px;
  margin: 10px;
}
.order-status h5 {
  color: #4b4b4b;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
}
.order-status b {
  color: #343434;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}
.order-status-detail {
  display: flex;
  justify-content: space-between;
}
.order-status-detail h4 {
  color: #000;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
.order-status-detail h6 {
  color: #5d5d5d;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
}
.order-status-detail i {
  color: #0397dd;
}
.order-status small {
  color: #4e4e4e;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
}
.pending {
  color: #ddad03;
}
@media (max-width: 560px) {
  .order-status-detail {
    display: block;
  }
}
.wrapper {
  font-size: 14px;
}
.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}
.StepProgress::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: auto;
  border-left: 2px solid #0397dd;
}
.StepProgress-item {
  position: relative;
  counter-increment: list;
}
.StepProgress-item:not(:last-child) {
  padding-bottom: 50px;
}
.StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}
.StepProgress-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -39px;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
}
.StepProgress-item.is-done::before {
  border-left: 2px solid #0397dd;
}
.StepProgress-item.is-done::after {
  content: "✔";
  font-size: 10px;
  color: #fff;
  padding-top: 2px;
  text-align: center;
  border: 2px solid #0397dd;
  background-color: #0397dd;
}
.StepProgress-item.current::before {
  border-left: 2px solid #0397dd;
}
.StepProgress-item.current::after {
  content: counter(list);
  padding-top: 0;
  width: 20px;
  height: 20px;
  top: 1px;
  left: -39px;
  font-size: 12px;
  text-align: center;
  color: #0397dd;
  border: 2px solid #0397dd;
  background-color: white;
}
.StepProgress strong {
  display: block;
}
.StepProgress li {
  color: #5d5d5d;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.13px;
}
.StepProgress strong {
  color: #000;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.16px;
}
.chat-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*** raise-ticket CSS ***/
.ticket-heading h4 {
  color: #212121;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
}
.ticket-list h4 {
  color: #212121;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}
.ticket-list h5 {
  color: #565656;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}
.ticket-list h6 {
  color: #565656;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}
.ticket-list {
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 4px 30px 1px rgba(224, 224, 224, 0.24);
  padding: 10px;
}
.support-ticket h4 {
  color: #212121;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
}
.support-link {
  color: #0397dd;
  font-family: "Euclid Circular A";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
}
.ticket-infoform label {
  color: #373737;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  padding-bottom: 7px;
}
.ticket-infoform input::placeholder {
  color: #929292;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
}
.ticket-infoform select {
  color: #929292;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
  height: 34px;
}
.ticket-infoform input {
  height: 34px;
}
.ticket-infoform {
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 4px 30px 1px rgba(224, 224, 224, 0.24);
  padding: 15px 10px;
}
.fa-font {
  background: #c96;
  color: #c96;
}
.editor {
  position: relative;
}
.editorAria {
  height: 100%;
  min-height: 300px;
  border: 1px solid #ddd;
  overflow-y: auto;
  padding: 1em;
  margin-top: -2px;
  outline: none;
  background-color: #fff;
}
.editorAria p {
  font-size: 14px;
}
.toolbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
}

.toolbar a,
.fore-wrapper,
.back-wrapper {
  border: 1px solid #ddd;
  background: #fff;
  font-family: "Candal";
  color: #000;
  padding: 5px;
  margin: 2px 0px;
  width: 35px;
  height: 35px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.toolbar a:hover,
.fore-wrapper:hover,
.back-wrapper:hover {
  background: #0eacc6;
  color: #fff;
  border-color: #0eacc6;
}


.fore-wrapper,
.back-wrapper {
  position: relative;
  cursor: auto;
}
.fore-palette,
.back-palette {
  display: none;
  cursor: auto;
}

.fore-wrapper:hover .fore-palette,
.back-wrapper:hover .back-palette {
  display: block;
}

.fore-wrapper .fore-palette,
.back-wrapper .back-palette {
  position: relative;
  display: inline-block;
  cursor: auto;
  display: block;
  left: 0;
  top: calc(100% + 5px);
  position: absolute;
  padding: 10px 5px;
  width: 160px;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  display: none;
  text-align: left;
}
.fore-wrapper .fore-palette:after,
.back-wrapper .back-palette:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}
.fore-palette a,
.back-palette a {
  background: #fff;
  margin-bottom: 2px;
  border: none;
}
.editor img {
  max-width: 100%;
  object-fit: cover;
}
.attachment-file input {
  font-size: 14px;
  font-family: "Euclid Circular A";
}
.attachment-file small {
  color: #838383;
  font-family: "Poppins";
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 342.857% */
  letter-spacing: 0.35px;
}
.sub-cancel-btns button{
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
letter-spacing: 0.7px;
text-transform: uppercase;
padding: 5px 20px;
}
.tick-submit{
  color: #fff;
  border-radius: 2px;
background: #0397DD;
border: none;
}
.tick-cancel{
  border-radius: 2px;
border: 1px solid #E7E7E7;
background: #FFF;
color: #737373;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: 0.7px;
}


/*** ADMIN CSS ***/
.admin-dash{
 background-color: #fff;
 border-radius: 30px;
 padding: 25px 5px;
filter: drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50));
display: flex;
align-items: center;
justify-content: space-evenly;
}
.admin-dash h6{
  color: #ACACAC;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-top: 5px;
}
.admin-dash h5{
  color: #333;
font-family: 'Poppins';
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 25px */
letter-spacing: -0.25px;
}
#table_detail select{
  border: none;
  font-size: 14px;

}
#table_detail select:focus{
  box-shadow: none;
}
.ticket-viewbox h4{
  color: #212121;
font-family: 'Euclid Circular A';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.8px;
}
.ticket-desc-box h4{
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
}
.ticket-desc-box h6{
  overflow: hidden;
color: #001234;
text-overflow: ellipsis;
font-family: 'Euclid Circular A';
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 25.6px; /* 196.923% */
}
.down-btn {
  font-family: inherit;
  display: inline-block;
  padding: 1px 20px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid #0397DD;
  transition: color .5s;
  z-index: 1;
  font-size: 15px;
  border-radius: 6px;
  font-weight: 500;
  color: #0397DD;
  background-color: transparent;
 }
 
 .down-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #0397DD;
  height: 150px;
  width: 200px;
  border-radius: 50%;
 }
 
 .down-btn:hover {
  color: #fff;
 }
 
 .down-btn:before {
  top: 100%;
  left: 100%;
  transition: all .7s;
 }
 
 .down-btn:hover:before {
  top: -30px;
  left: -30px;
 }
 
 .down-btn:active:before {
  background: #0397DD;
  transition: background 0s;
 }
 .modal-body label{
  color: #373737;
font-family: 'Poppins';
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.72px;
padding-bottom: 5px;
 }
 .modal-body input::placeholder{
  color: #929292;
  font-family: 'Poppins';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
 }
 .modal-body textarea::placeholder{
  color: #929292;
  font-family: 'Poppins';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
 }
 .modal-body button{
  border-radius: 3px;
background: #0397DD;
padding: 5px 20px;
color: #fff;
border: none;
font-size: 15px;
 }
 .service-update-btn{
  display: flex;
  justify-content: center;
 }
 .pending .bi-check-circle-fill{
  color: #DDAD03;
 }
 .pending-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .pending-box button{
  background-color: #0397DD;
  color: #fff;
  font-size: 13px;
  padding: 3px 10px;
  border: none;
  border-radius: 2px;
 }
 .admin-staffnav i{
  color: #2D9CDB;
  background: var(--transparency-quantinery, rgba(45, 156, 219, 0.15));
 }
 .admin-staffnav .bi-chat-square-dots{
  color: #2D9CDB;
  border-radius: 10px;
  background: var(--transparency-quantinery, rgba(45, 156, 219, 0.15));
  padding: 6.5px 10px;
 }
 .vertical-line{
  border: none;
  border-right: 2px solid #D0D6DE;
 }
 .admin-staffnav{
  display: flex;
 }
 .nav-menu h4{
  color: #000;
font-family: 'Euclid Circular A';
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.98px;
text-transform: uppercase;
}
.nav-menu span{
  color: #0397DD;
}






.side-msgbox {
  display: flex;
  align-items: center;
}
.msg-text h5 {
  color: #62afe7;
  font-family: "Euclid Circular A";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.42px;
}
.msg-text span {
  font-size: 10px;
}
.msg-text h6 {
  color: #4b4b4b;
  font-family: "Euclid Circular A";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0 !important;
}
.msg-text small {
  color: #878787;
  font-family: "Euclid Circular A";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.left-msg-column {
  background-color: #fff;
}
.received-text {
  width: auto;
  border-radius: 3px;
  background: #d7ffe8;
  padding: 5px;
}
.received-text p {
  margin-bottom: 0 !important;
  color: #878787;
  font-family: "Euclid Circular A";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  width: 250px;
}
.received-chat {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
}
.sent-chat {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
}
.sent-text {
  border-radius: 3px;
  background: #f8f8f8;
  padding: 5px;
  width: auto;
}
.sent-text p {
  margin-bottom: 0 !important;
  color: #878787;
  font-family: "Euclid Circular A";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  width: 250px;
}
.date-time-info {
  display: flex;
  justify-content: end;
}
.date-time-info small {
  color: #878787;
  font-family: "Euclid Circular A";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 260% */
}
.rec-date-time-info {
  display: flex;
  justify-content: start;
}
.rec-date-time-info small {
  color: #878787;
  font-family: "Euclid Circular A";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 260% */
}
.right-chat-column {
  background: #fff;
  border-left: 1px solid #e5e5e5;
}
.message-row {
  background: #fff;
  box-shadow: 0px 2px 30px 1px rgba(224, 224, 224, 0.25);
}
.type-msg-box {
  display: flex;
  align-items: center;
}
.type-msg-box button {
  border-radius: 2px;
  background: #09b451;
  color: #fff;
  padding: 6px 20px;
  border: none;
  font-family: "Euclid Circular A";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.type-msg-box input::placeholder {
  color: #bebebe;
  font-family: "Euclid Circular A";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.type-msg-box input {
  border-radius: 2px;
  border: 1px solid #eaeaea;
  background: #fff;
}
.text-chat-section {
  overflow-y: auto;
  height: 360px;
}
.left-msg-column {
  overflow-y: auto;
  height: 360px;
}

.text-chat-section::-webkit-scrollbar {
  display: none;
}

.text-chat-section {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.left-msg-column::-webkit-scrollbar {
  display: none;
}

.left-msg-column {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.message-seacrhbox {
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 30px 1px rgba(224, 224, 224, 0.25);
  padding: 10px 0;
}