body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.switch-box{
  display: inline-block;
  position: relative;
}
.switch-box input {
  position: absolute;
  top: 5px;
  left:-20px;
  width: 100%;
  height: 70%;
  opacity: 1;
  cursor: pointer;
  z-index: 9;
}
.switch-box input::after {
  content: "";
  margin-left: 13px;
  position: absolute;
  top: 5px;
}

.switch-box button {
  position: relative;
  display :block;
  opacity: 1;
}
.switch-box label {
  position: relative;
  cursor: pointer;
  display :block;
  width: 42px;
  height: 19px;
  background: #cccccc;
  border-radius: 50px;
  transition: 300ms linear;
}

.switch-box label:before {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
  transition: left 300ms linear;
}

.switch-box input:checked + label {
  background: #09B451;
}

.switch-box input:checked + label:before {
  left: 23px;
}

.fixed-button-container {
  display: none;
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 999;
}

.fixed-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.filebutton_box{
  position: relative;
}
.filebutton_box .fileupload{
  /* position: absolute; */
  width:100px;
  /* display: none; */
  opacity: 1;
  cursor: pointer;
}
/* .fileupload::after{
  position: absolute;
  z-index: 99;
  left:0;
  top:0;
  opacity: 1;
  content: "Select File";
} */

.status_select{
  width: 120px;
}

.replybox{
  max-height:500px;
  overflow: auto;
}

.chatuser{
  cursor: pointer;
  position: relative;
}

.chatuser:hover{
  background-color: aquamarine;
}

.active-chat{
  background-color:aquamarine;
}

.viewbtn{
  font-size:20px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  padding: 3px;
}
.progressbtn{
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  border: 1px solid #007bff;
  padding: 5px;
}